import React from 'react';
import RichText, { RichTextTypes } from '@utils/parsers/RichText';
import Cards from '@organisms/Cards/Cards';
import Toplist from '@organisms/Toplist/Toplist';
import Banner from '@molecules/Banner';
import Link from '@atoms/Link';
import Table from '@molecules/Table';
import Paragraph from '@molecules/Paragraph';
import Accordion from '@molecules/Accordion';
import List from '@molecules/List';
import ConstructedImages from '@molecules/ConstructedImages/ConstructedImages';
import Columns from '@molecules/Columns';
import AlertBanner from '@molecules/AlertBanner';
import HitsAndMisses from '@molecules/HitsAndMisses';

const SidebarLayoutProxy = ({ component }: any) => {
  switch (component.sys?.contentType.sys.id) {
    case 'accordion':
      return <Accordion data={component} />;
    case 'alertBanner':
      return <AlertBanner data={component} />;
    case 'banner':
      return <Banner data={component} />;
    case 'columns':
      return <Columns data={component} />;
    case 'contentLink':
      return <Link data={component} />;
    case 'constructedImages':
      return <ConstructedImages data={component} />;
    case 'toplist':
      return <Toplist data={component} />;
    case 'table':
      return <Table data={component} />;
    case 'paragraph':
      return <Paragraph data={component} />;
    case 'cards':
      return <Cards data={component} />;
    case 'list':
      return <List data={component} />;
    case 'hitsAndMisses':
      return <HitsAndMisses data={component} />;
    case 'heading':
      return (
        <RichText
          data={component.body}
          type={RichTextTypes.FULL}
          tag={`internalName | ${component.sys?.contentType.sys.id}`}
        />
      );
    default:
      return null;
  }
};

export default SidebarLayoutProxy;
