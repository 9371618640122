import React from 'react';
import MainTemplate from '@templates/MainTemplate/MainTemplate';
import BackToTop, { BottomMargin } from '@atoms/BackToTop';
import Section from '@utils/grid/Section/Section';
import SidebarLayoutProxy from '@utils/proxies/SidebarLayoutProxy/SidebarLayoutProxy';
import Sidebar from '@molecules/Sidebar/Sidebar';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import Hero from '@molecules/Hero/Hero';

type sideBarTemplateProps = {
  pageContext: {
    pageData: {
      components: [];
      tabs: any;
      id: string;
      hero: {};
      mustReads: [{}];
      breadcrumbLinks: [{ id: string; text: string; link: string }];
      topFullWidthSection: [{}];
      sidebarSection: [{}];
      pageInfo: {
        title: string;
      };
      mainTemplate: {
        footer: {} | null;
        navigation: {} | null;
      };
    };
  };
};

const sideBarTemplate = ({
  pageContext: { pageData },
}: sideBarTemplateProps) => {
  const {
    hero,
    breadcrumbLinks,
    topFullWidthSection,
    sidebarSection,
    mustReads,
    pageInfo,
    mainTemplate,
  } = pageData;

  return (
    <MainTemplate data={mainTemplate} page={pageInfo}>
      <Breadcrumbs
        breadcrumbLinks={breadcrumbLinks}
        pageTitle={pageInfo.title}
        className="breadcrumb-selector col-span-full"
      />
      <Hero data={hero} />
      <div className="col-span-4 flex w-full flex-col container-spacing pt-32 lg:pt-48">
        {topFullWidthSection &&
          topFullWidthSection.map((section: any) => {
            if (!section) return null;

            return (
              <Section
                key={section.id}
                id={section.id}
                data={section}
                className="mx-auto flex w-full max-w-[calc(100%-32px)] flex-col section-spacing xl:max-w-[1280px] xl:px-16"
              >
                {section.components &&
                  section.components.map((component: any) => {
                    if (component.id !== undefined) {
                      return (
                        <SidebarLayoutProxy
                          key={component.id}
                          component={component}
                        />
                      );
                    }
                  })}
              </Section>
            );
          })}

        {sidebarSection && (
          <div className="container clear-both grid grid-cols-4 items-start container-spacing">
            <div className="col-span-4 flex flex-col container-spacing lg:col-span-3">
              {sidebarSection.map((section: any) => {
                return (
                  <Section
                    key={section.id}
                    id={section.id}
                    data={section}
                    className="mx-auto flex w-full flex-col section-spacing lg:max-w-[calc(100%-32px)] xl:max-w-[1280px]"
                  >
                    {section.components &&
                      section.components.map((component: any) => {
                        if (component.id !== undefined) {
                          return (
                            <SidebarLayoutProxy
                              key={component.id}
                              component={component}
                              section={section}
                            />
                          );
                        }
                      })}
                  </Section>
                );
              })}
            </div>
            <div className="mlg:col-span-1 hidden lg:flex lg:h-full lg:flex-col">
              <Sidebar
                data={pageData}
                className="sticky md:top-[90px]"
                sections={sidebarSection}
              />
            </div>
          </div>
        )}

        {mustReads && (
          <div className="bg-denim-blue-100/10">
            <div className="container flex flex-col section-spacing section-with-background">
              {mustReads &&
                mustReads.map((component: any) => (
                  <SidebarLayoutProxy
                    key={component.id}
                    component={component}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
      <BackToTop size={BottomMargin.DEFAULT} />
    </MainTemplate>
  );
};

export default sideBarTemplate;
